import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'antd';
import { Auth, Hub } from 'aws-amplify';
import { Link } from 'react-router-dom';
import Form from './Form';
import UserContext from './UserContext';
import Utilities from '../src/utility/utilities';
import './Profile.css';

const Profile = (props) => {

  const userContext = useContext(UserContext);
  console.log('userContext as seen by Profile Page: ', userContext);

  const [user, setUser] = useState(null);

  useEffect(() => {
    checkUser();
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signOut') {
        setUser(null)
      }
    });
  }, []);

  const goToCollections = () => {
    checkUser();
    if (user.username !== undefined) Utilities.sendPayLoad(props, '/collections', user);
  }

  async function checkUser() {
    try {
      const data = await Auth.currentUserPoolUser();
      const userInfo = { username: data.username, ...data.attributes };
      setUser(userInfo);

    } catch (err) { console.log('error: ', err) }
  }

  const handleClickUploadCollection = () => {

  }

  function signOut() {
    Auth.signOut()
      .catch(err => console.log('error signing out: ', err))
  }

  if (user) {
    if (userContext.username === undefined) userContext.username = user.username;
    return (
      <>
        <div className="profile-container">
          <h1>Profile</h1>
          <div className="profile">
            <div className="profile__main">
              <div className="left-pane">
                <div className="artists-details">
                  <div className="artist-avatar" style={{ backgroundImage: 'url("/images/avatar3.png")' }}></div>
                  <div className="artist-details-wrapper">
                    <p className="artist-details"><span className="artist-name">{user.username}</span> - <span className="artist-type">Musician</span></p>
                    <p className="artist-description">"Shoshin-learner."
                      {/* <a href="https://twitter.com/whereisbastion" target="_new"><img className="icon-twitter" src="/images/icon_twitter.svg" />@whereisbastion</a>&nbsp;<a href="https://instagram.com/bastionmusic" target="_new"><img className="icon-instagram" src="/images/icon_instagram.svg" />@bastionmusic</a> */}
                    </p>
                  </div>
                  <div className="account-details">
                  <p>Username: {user.username}</p>
                  <p>Email: {user.email}</p>
                  <p>Phone: {user.phone_number}</p>
                  <p>Wallet Address: {userContext.user_wallet_address}</p>
                  </div>
                </div>
              </div>
              <div className="right-pane">
                <div className='action-items'>
                  <Link to='/uploadcollection'>
                    <button className="collab-request" onCLick={handleClickUploadCollection}>Upload your collection</button>
                  </Link>
                  <br/>
                  <br/>
                  <p>
                  In the mood to collaborate? <br></br>
                  Visit the <Link style={{ textDecoration: 'underline' }} to='/VisualDiscoveryPortal'>Visual Discovery Portal</Link> to discover upcoming artits, 
                  or check out the <Link style={{ textDecoration: 'underline' }} to='/MusicDiscoveryPortal'>Music Discovery Portal</Link> to find like-minded musicians.
                  </p>
                  <br /><br /><br />
                </div>
              </div>
            </div>
          </div>
          <Button onClick={signOut}>Sign Out</Button>
          <br></br>
          <Button onClick={goToCollections}>Proceed</Button>
        </div>


      </>

    );
  }
  return <Form setUser={setUser} />
}

export default Profile
