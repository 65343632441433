const key = "8fd1e3c28df90379e0c0";
const secret = "f1def6520c86733aa8fba71641a1d6f1d96a585be5eb06d281f633fb1b53afe5";
const axios = require('axios');
const BASE_PINATA_URI = "https://mashnft.mypinata.cloud/ipfs";

export const pinJSONToIPFS = async(metadata) => {
    var data = JSON.stringify({
      "pinataOptions": {
        "cidVersion": 1
      },
      "pinataContent": metadata
    });

    var config = {
      method: 'post',
      url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
      headers: {
        'Content-Type': 'application/json',
        pinata_api_key: key,
        pinata_secret_api_key: secret
      },
      data : data
    };

    const ipfs_receipt = await axios(config);
    const metadata_uri = `${BASE_PINATA_URI}/${ipfs_receipt.data.IpfsHash}`;
    console.log(metadata_uri);
    return metadata_uri;
};

export default pinJSONToIPFS;
