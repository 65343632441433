import React from 'react';
import './VisualRow.css';

const VisualRow = ({item, parentCallback}) => {
  
  const handleRequestCollabClick = (id, requestee) => {
    parentCallback(id, requestee);
  }

  return (
    <div className="visualrow">
      <div className="artistrow__main">
        <div className="artist-container">
          <div className="content-wrapper">
            <p><a href={`https://www.mashnft.xyz/#/${item.creator_displayname}`}>{item.creator_displayname}</a></p>
            <p className="label">Artist</p>
          </div>
        </div>
        <div className="collection-container">
          <div className="content-wrapper">
            <p><a href={item.collectionUrl}>{item.title}</a></p>
            <p className="label">Collection</p>
          </div>
        </div>
        <div className="media-container">
          <div className="content-wrapper">
            <div className="scroller">
              {
                item.collection.map(collectionItem => {
                  return (<img src={collectionItem.thumbnail_ipfs_url} />)
                })
              }
            </div>
          </div>
        </div>
        <div className="request-container">
          <button onClick={() => handleRequestCollabClick(item.collection_id, item.creator_displayname)} className="request-collab">Request Collaboration</button>
        </div>
      </div>
    </div>
  )

}

export default VisualRow;