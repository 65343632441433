/*
Shared utilities
*/

const Utilities = {

    goToLink: (url) => {
        document.location.href = url;
    },
    sendPayLoad: (props, pathName, payLoad) => {
        props.history.push({
            pathname: pathName,
            payLoad: payLoad
        });
    }

}

export default Utilities;