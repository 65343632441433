const { Alchemy, Network, Wallet, Utils } = require("alchemy-sdk");
const CONTRACT_ABI = require("../abi/mash_collection_OLD.json");
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(
    "https://eth-sepolia.g.alchemy.com/v2/pUzxr-Vs9CImMU6Vf7mM3rFCoidSfEek"
);

export const getContractInstance = async (mash_collection_contract_address) => {
    return new web3.eth.Contract(CONTRACT_ABI, mash_collection_contract_address);
}

export const getMaxSupply = async (mash_collection_contract_instance) => {
  const max_tokens = await mash_collection_contract_instance.methods.getMaxSupply().call();
  return max_tokens;
};

export const getMintPrice = async (mash_collection_contract_instance) => {
  const mint_price = await mash_collection_contract_instance.methods.getMintPrice().call();
  return mint_price;
};

export const getCreatorRewardsEscrowManagerContractAddress = async (mash_collection_contract_instance) => {
  const escrow_contract_address = await mash_collection_contract_instance.methods.escrow().call();
  return escrow_contract_address;
};

export const getMashCollectionMediaMetadataContractAddress = async (mash_collection_contract_instance) => {
  const media_metadata_contract_address = await mash_collection_contract_instance.methods.media_metadata().call();
  return media_metadata_contract_address;
};

export const getVisualMediaAndMetadata = async (mash_collection_media_metadata_contract_instance) => {
  const visual_media = await mash_collection_media_metadata_contract_instance.methods.getVisualMediaAndMetadata().call();
  return visual_media;
};

export const getAudioMediaAndMetadata = async (mash_collection_media_metadata_contract_instance) => {
  const audio_media = await mash_collection_media_metadata_contract_instance.methods.getAudioMediaAndMetadata().call();
  return audio_media;
};

export const mintNFT = async (token_uri, mash_contract_address, mint_price) => {
    const contract = await new web3.eth.Contract(CONTRACT_ABI, mash_contract_address);

    const user_address = window.ethereum.selectedAddress;
    const nonce = await web3.eth.getTransactionCount(user_address, 'latest');
    const standard_gas_limit = Utils.parseUnits("30000", "gwei");
    const latest_block = await web3.eth.getBlock("latest");
    const latest_gas_limit = String(latest_block.gasLimit) ? (latest_block.gasLimit <= standard_gas_limit) : String(standard_gas_limit);

    const tx = {
        'to': mash_contract_address,
        'from': user_address,
        'nonce': nonce.toString(),
        'value': "0x" + web3.utils.toBN(web3.utils.toWei(mint_price.toString(), "ether")).toString(16),
        'data': contract.methods.mint(user_address, token_uri) .encodeABI(),
    };

    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [tx],
            });

        return {
            success: true,
            status: "MINT_SUCCESS",
        }
    } catch (error) {
        return {
            success: false,
            status: "MINT_FAILURE"
        }
    }
}