import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import './Featured.css';

const Featured = (props) => {

  const [collection1, setCollection1] = useState({
    "id": "2",
    "collection_contract_address": "",
    "type": "collection",
    "title": "Amuse-Bouche",
    "description": "Something to tantalize the mind and whet the palate.",
    "creator": "b0neher0",
    "owner_address": "",
    "unit_price": "",
    "mash_fee": "",
    "supply": 4,
    "tags": [],
    "collectionContractAddress": "0x5d9B65318ee69fCBeCb916160bC115315ad3b8Ee",
    "visual_creator_name": "b0neher0",
    "audio_creator_name": "Dirty Chefs",
    "price": 4
  });

  const [collection2, setCollection2] = useState({
    "id": "3",
    "collection_contract_address": "",
    "type": "collection",
    "title": "Mirage",
    "description": "Mara & Bastion met on the dance floor and fell in love. The song is inspired by the music that brought us together, and the visuals (filmed, performed, and edited by the artists) are a stylized expression of the attraction and intimacy we felt in that moment.",
    "creator": "Bastion",
    "owner_address": "",
    "unit_price": "",
    "mash_fee": "",
    "supply": 4,
    "tags": [],
    "collectionContractAddress": "0x8F20F6862639d2EdD69795fcbFc7F8DF72976ae3",
    "visual_creator_name": "Mara",
    "audio_creator_name": "Bastion",
    "price": 4
  });

  return (
    <>
    <div className="featured">

      <div className='collection-details-wrapper'>
        <p className="collection-title">Amuse-Bouche</p>
        <p className="collection-creators">b0neher0 <span>x</span> Dirty Chefs</p>
        <p className="collection-description">Something to tantalize the mind and whet the palate.</p>
      </div>

      <div className="media-wrapper">
        <div className="media-players">

            {/* video */}
            <ReactPlayer 
              url='https://mashnft.mypinata.cloud/ipfs/QmNRnJ3xptqyXoXsXfvtNp4EqnHJVTiHnE36cX6KYBEvRU/dirtychefs_genc_1/collection/B0NEHER01%20-%20COVER%20for%20collection.mp4' 
              controls={true}
              playing={true}
              loop={true}
              width="100%" 
            />
            
            {/* audio */}
            <ReactPlayer 
              url='https://mashnft.mypinata.cloud/ipfs/QmNRnJ3xptqyXoXsXfvtNp4EqnHJVTiHnE36cX6KYBEvRU/dirtychefs_genc_1/Audio/COVER.mp3' 
              controls={true}
              width="100%"
              height="30px" 
            />
        </div>
      </div>

      <div className="artists-details">
        <div className="artist-avatar" style={{ backgroundImage: 'url("https://mashnft.mypinata.cloud/ipfs/QmNRnJ3xptqyXoXsXfvtNp4EqnHJVTiHnE36cX6KYBEvRU/dirtychefs_genc_1/collection/B0NEHER0%20-%20PFP.png")' }}></div>
        <div className="artist-details-wrapper">
          <p className="artist-details"><span className="artist-name">b0neher0</span> - <span className="artist-type">Visual Artist</span></p>
          <p className="artist-description">"Levity and fun in the face of the morbid, an introduction to the b0ne series of skeletons that serve as a reminder of mortality, but to not take life or death too seriously, and the many aesthetics that are inbetween both realms."</p>
        </div>
      </div>

      <div className="artists-details">
        <div className="artist-avatar" style={{ backgroundImage: 'url("https://mashnft.mypinata.cloud/ipfs/QmNRnJ3xptqyXoXsXfvtNp4EqnHJVTiHnE36cX6KYBEvRU/dirtychefs_genc_1/collection/Dirty%20Chefs%20-%20PFP.png")' }}></div>
        <div className="artist-details-wrapper">
          <p className="artist-details"><span className="artist-name">Dirty Chefs</span> - <span className="artist-type">Musician</span></p>
          <p className="artist-description">"This is not a mirage. It is an oasis in the desert, devoid of flavor. Stressed? That's just desserts backwards."<br></br>
          <a href="https://instagram.com/enjoydirtychefs" target="_new"><img className="icon-instagram" src="/images/icon_instagram.svg" />@enjoydirtychefs</a>
          </p>
        </div>
        
        <div className="button-wrapper">
          <Link className="collection-card-wrapper" to={`/mashboard?collection_id=${encodeURIComponent(collection1.id)}`}>
            <button style={{ backgroundColor: "#f18736", whiteSpace: "nowrap" }} className="inline-flex items-center justify-center px-2 py-0 border border-transparent text-base font-small text-white hover:bg-indigo-700" type="button">
              <span style={{ fontWeight: 600, fontSize: "18px", padding: "10px 20px" }}>explore collection</span>
            </button>
          </Link>
        </div>

      </div>
    </div>


    <div style={{ clear: "both" }}><br></br><br></br></div>


    <div className="featured">

      <div className='collection-details-wrapper'>
        <p className="collection-title">Mirage</p>
        <p className="collection-creators">Mara <span>x</span> Bastion</p>
        <p className="collection-description"></p>
      </div>

      <div className="media-wrapper">
        <div className="media-players">

            {/* video */}
            <ReactPlayer 
              url='https://mashnft.mypinata.cloud/ipfs/QmQADdcZAW9ms9Bi2fSpk4ae8PoYLG9VMXwySXvnAgtWmu/bastion_mara_visual/Touch.mp4' 
              controls={true}
              playing={true}
              loop={true}
              width="100%" 
            />

            {/* audio */}
            <ReactPlayer 
              url='https://mashnft.mypinata.cloud/ipfs/QmQADdcZAW9ms9Bi2fSpk4ae8PoYLG9VMXwySXvnAgtWmu/bastion_mara_audio/Bastion%20-%20Mirage.wav' 
              controls={true}
              width="100%"
              height="30px" 
            />
        </div>
      </div>

      <div className="artists-details">
        <div className="artist-avatar" style={{ backgroundImage: 'url("https://mashnft.mypinata.cloud/ipfs/QmQADdcZAW9ms9Bi2fSpk4ae8PoYLG9VMXwySXvnAgtWmu/collection/Mara-PFP.png")' }}></div>
        <div className="artist-details-wrapper">
          <p className="artist-details"><span className="artist-name">Mara</span> - <span className="artist-type">Visual Artist</span></p>
          <p className="artist-description">
          "Mara is a professional photographer and model from Vancouver currently based in Brooklyn. Inspired by arthouse cinema and festival/rave culture, her stylized portraits and videography elevate and enhance her subject matter."
          <br></br>
          <a href="https://instagram.com/maraf.oto" target="_new"><img className="icon-instagram" src="/images/icon_instagram.svg" />@maraf.oto</a></p>
        </div>
      </div>

      <div className="artists-details">
        <div className="artist-avatar" style={{ backgroundImage: 'url("https://mashnft.mypinata.cloud/ipfs/QmQADdcZAW9ms9Bi2fSpk4ae8PoYLG9VMXwySXvnAgtWmu/collection/Bastion-PFP.png")' }}></div>
        <div className="artist-details-wrapper">
          <p className="artist-details"><span className="artist-name">Bastion</span> - <span className="artist-type">Musician</span></p>
          <p className="artist-description">"Inspired by pioneers in electronic music & cultures from around the world, Bastion combines traditional instruments and mind-bending sound design to create his cinematic blend of world bass and house music."
          <br></br>
          <a href="https://twitter.com/whereisbastion" target="_new"><img className="icon-twitter" src="/images/icon_twitter.svg" />@whereisbastion</a>&nbsp;<a href="https://instagram.com/bastionmusic" target="_new"><img className="icon-instagram" src="/images/icon_instagram.svg" />@bastionmusic</a></p>
        </div>
        
        <div className="button-wrapper">
          <Link className="collection-card-wrapper" to={`/mashboard?collection_id=${encodeURIComponent(collection2.id)}`}>
            <button style={{ backgroundColor: "#f18736", whiteSpace: "nowrap" }} className="inline-flex items-center justify-center px-2 py-0 border border-transparent text-base font-small text-white hover:bg-indigo-700" type="button">
              <span style={{ fontWeight: 600, fontSize: "18px", padding: "10px 20px" }}>explore collection</span>
            </button>
          </Link>
        </div>

      </div>

      <div style={{ clear: "both" }}><br></br><br></br></div>

    </div>
  </>

  );
}

export default Featured;
