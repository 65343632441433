import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './Profile.css';

const Bastion = (props) => {

  const [collection2, setCollection2] = useState({
    "id": "3",
    "collection_contract_address": "",
    "type": "collection",
    "title": "Mirage",
    "description": "Mara & Bastion met on the dance floor and fell in love. The song is inspired by the music that brought us together, and the visuals (filmed, performed, and edited by the artists) are a stylized expression of the attraction and intimacy we felt in that moment.",
    "creator": "Bastion",
    "owner_address": "",
    "unit_price": "",
    "mash_fee": "",
    "supply": 4,
    "tags": [],
    "collectionContractAddress": "0x8F20F6862639d2EdD69795fcbFc7F8DF72976ae3",
    "visual_creator_name": "Mara",
    "audio_creator_name": "Bastion",
    "price": 4
  });

  return (
    <div className="profile">
      <div className="profile__main">
        <div className="left-pane">
          <div className="artists-details">
            <div className="artist-avatar" style={{ backgroundImage: 'url("https://mashnft.mypinata.cloud/ipfs/QmQADdcZAW9ms9Bi2fSpk4ae8PoYLG9VMXwySXvnAgtWmu/collection/Bastion-PFP.png")' }}></div>
            <div className="artist-details-wrapper">
              <p className="artist-details"><span className="artist-name">Bastion</span> - <span className="artist-type">Musician</span></p>
              <p className="artist-description">"Inspired by pioneers in electronic music & cultures from around the world, Bastion combines traditional instruments and mind-bending sound design to create his cinematic blend of world bass and house music."
              <br></br>
              <a href="https://twitter.com/whereisbastion" target="_new"><img className="icon-twitter" src="/images/icon_twitter.svg" />@whereisbastion</a>&nbsp;<a href="https://instagram.com/bastionmusic" target="_new"><img className="icon-instagram" src="/images/icon_instagram.svg" />@bastionmusic</a></p>
            </div>
          </div>
        </div>
        <div className="right-pane">

          <div className='action-items'>
            {/* ToDo: Logic: Show if already a member and currently logged in */}
            <p><strong>Bastion</strong> is open for collaboration. If you are interested in mashing your work with <strong>Bastion</strong>'s music, click the button below and we will notify <strong>Bastion</strong>.</p>
            <button className="collab-request">Request Collaboration</button>

            {/* ToDo: Logic: Show if not signed up */}
            <br/><br/><br/>
            <p>Not a member yet? Sign up <a href="/profile">here</a> to collaborate with emerging artists.</p>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Bastion;