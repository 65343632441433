import { request } from "stream-http";
import { belongsTo, createServer, hasMany, Model, RestSerializer } from "miragejs";
import * as factories from './factories';
import { seeds } from './seeds';

let applicationSerializer = RestSerializer.extend({ root: false, embed: true});

export default function () {
  createServer({
    models: {
      user: Model,
      collection: Model.extend({ 
        videos: hasMany(), // a collection has many videos
        audios: hasMany(), // a collection has many audios
        images: hasMany() // a collection has many images
      }),
      video: Model.extend({
        collection: belongsTo() // a video belongs to a collection
      }),
      audio: Model.extend({
        collection: belongsTo() // an audio belongs to a collection
      }),
      image: Model.extend({
        collection: belongsTo() // an image belongs to a collection
      }),
    },

    // factories: {
    //   video: factories.video
    // },

    // serializers: {
    //   application: applicationSerializer,
    //   video: applicationSerializer.extend({
    //     include: [
    //       'collection',
    //       'tags'
    //     ]
    //   })
    // },

    seeds,

    routes() {
      // These URLs should NOT be handled by Mirage!
      this.passthrough('https://cognito-idp.us-east-1.amazonaws.com/');
      // this.passthrough('https://my-json-server.typicode.com/'); // for debugging using json-server

      this.passthrough('https://api.pinata.cloud/pinning/pinJSONToIPFS/');

      // All requests within /api will be handled by Mirage! (example: /api/videos)
      this.namespace = '/api';

      this.get("/videos", (schema) => {
        return schema.videos.all()
      })

      this.get("/videos/:id", (schema, request) => {
        const videoId = parseInt(request.params.id);
        const data = JSON.parse(request.requestBody);
        const video = schema.videos.find(videoId);
        
        // return video.update({
        //   title: data.title,
        //   type: data.type
        // })

        return video;
      })

      this.get("/audios", (schema) => {
        return schema.audios.all()
      })

      this.get("/collections", (schema) => {
        return schema.collections.all()
      });

      // endpoint returns strictly collections whose collection_type is 'video'
      this.get("/collections/video", (schema) => {
        const videoCollections = schema.collections.all().filter(element => element.collection_type === 'video');
        console.log("videoCollections:", videoCollections);
        return videoCollections;
      });

      this.get("/collections/:id", (schema) => {
        const collectionId = parseInt(request.params.id);
        const data = JSON.parse(request.requestBody);
        const collection = schema.collections.find(collectionId);
        return collection;
      });

      this.get("/collection", (schema) => {
        return schema.collection.all()
      });

      this.get("/collection/:id", (schema) => {
        const collectionId = parseInt(request.params.id);
        const data = JSON.parse(request.requestBody);
        const videos = schema.video.find(collectionId);
        return videos;
      });
      
    },
  })
}