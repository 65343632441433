import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory, withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";
import AssetSelectionContext from './AssetSelectionContext';
import PreMashView from './PreMashView';
import Audios from './Audios';
import Utilities from '../../utility/utilities';
import protectedRoute from '../../protectedRoute';
import VideoCard from './VideoCard';
import AudioCard from './AudioCard';
import UserContext from '../../UserContext';
import { Link } from 'react-router-dom';
import './PreMash.css';

const PreMash = (props) => {

  let history = useHistory();
  const location = useLocation();
  const userContext = useContext(UserContext);
  const incomingPayload = location.payLoad; // note: it's always called "payLoad" from the location object
  
  const [ mashing, setMashing ] = useState(false); // if currently mashing
  const [ mashed, setMashed ] = useState(false); // if done mashing
  const [ mashedPayload, setMashedPayload ] = useState(incomingPayload);

  useEffect(() => {
    checkUsername();
  }, []);

  useEffect(() => {
    console.log('mashing state is now ', mashing);
  }, [mashing]);

  useEffect(() => {
    if (mashed) {
      console.log('MASHING is DONE ', mashed);
      // pass the payLoad to the premint page after successful MASH
      Utilities.sendPayLoad(props, '/premint', mashedPayload);
    }
  }, [mashed]);

  const triggerMashing = () => {
    // console.log("MASHing triggerred from ColumnB...");
    if (isWalletConnected()) {
      setMashing(true);
      // simulate some delay here to simulate the process of mashing"
      console.log("Currently Mashing.....");
      setTimeout(() => {
        // delay a few seconds then set "mashed" state to true (remove this when actual method for mashing is in place)
        // just set mashed varialbe to true upon successful mash
        setMashed(true);
        setMashing(false);
      }, 4000);
    } else {
      alert("Wallet not connected.\n🦊 Please connect your wallet before Mashing.\nClick the 'Connect Wallet' button at top right.");
    }
  }

  const isWalletConnected = () => {
    let walletConnected = false;
    if (userContext.user_wallet_address.length > 0 ) {
      walletConnected = true
    }
    return walletConnected;
  }

  const goToCollections = () => {
    Utilities.sendPayLoad(props, '/collections', mashedPayload);
  }

  const goToMashboard = () => {
    Utilities.sendPayLoad(props, '/mashboard', mashedPayload);
  }
  
  const checkUsername = () => {
    // this is a check to make sure the payLoad is still intact; sends user to Profile page to re-login if broken data flow
    if (incomingPayload.username === undefined) {
      Utilities.sendPayLoad(props, '/gate', mashedPayload);
    }
  }

  return (
    <AssetSelectionContext.Provider value={mashedPayload}>
      <div className="PreMash">
        <Helmet htmlAttributes>
          <html lang="en" />
          <title>Mashboard - PreMash</title>
          <meta name="description" content="Mashboard PreMash" />
        </Helmet>
        <div className="preview-container"> 
          <Link style={{ cursor: "pointer" }} to={`/mashboard?collection_id=${encodeURIComponent(mashedPayload.id)}`} payLoad={mashedPayload}>
            &lt; Back to Selection Page
          </Link>
        
          <PreMashView parentCallback={triggerMashing}></PreMashView>
        </div>
        <div className="selection-container">
          <div className="flow-wrapper">
            <p>Your selected Audio and Video are shown below.<br></br>
                Please confirm and then click the MASH button.
            </p>
          </div>
          <div className="videocard-wrapper">
            <VideoCard item={incomingPayload.video}></VideoCard>
          </div>
          <div className="audiocard-wrapper">
            <AudioCard item={incomingPayload.audio}></AudioCard>
          </div>
        </div>
        {
          mashing ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  {/* the modal itself */}
                <div className="relative w-auto my-6 mx-auto max-w-3xl" style={{ width: '70%', color: 'black' }}>
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between py-1 px-4 border-b border-solid border-slate-200 rounded-t">
                      <h4 className="py-2 text-sm font-semibold">
                        MASHing in progress....
                      </h4>
                    </div>
                    {/*body*/}
                    <div style={{ textAlign: 'center', width: '100%', border: '1px dotted gray' }} className="relative p-1">

                      <p>Please wait...</p>
                      <p>We are currently generating a new mashed movie clip for you...</p>
                      <p>Your new MASH clip will be ready soon!</p>
                      <br></br>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null
        }
      </div>
    </AssetSelectionContext.Provider>
  );
}

export default protectedRoute(PreMash);
