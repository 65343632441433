import React from 'react';
import './AudioCard.css';


const AudioCard = ({item}) => {
  const { collectionContractAddress, creator, description, duration, title, thumbnail_ipfs_url } =  item;
  return (
    <div className="audiocard-container">
        <div className="thumbnail-container w-full h-117">
          <img className="w-full h-full object-cover" src={ thumbnail_ipfs_url } />
        </div>
        <hr className="divider"></hr>
        <div className="data-container">
          <p className="title">
            {title.length > 26 ? String(title).substring(0, 25) + "..." : title}
          </p>
          <p className="creator">
            {creator.length > 26 ? String(creator).substring(0, 25) + "..." : creator}
          </p>
          <p>
            <span>Format: </span><span>{item.format}</span>
          </p>
        </div>
      </div>
  );
}

export default AudioCard;
