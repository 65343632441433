import { Factory } from "miragejs";

export default Factory.extend({
    id: 1,
    name: 'video name',
    title: 'video title',
    description: 'Description',
    collection: 'collection',
    creator: 'Creator name',
    length: '10s',
    format: 'mov',
    tags: []
})