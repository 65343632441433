import React from 'react';
import './VideoCard.css';

const VideoCard = ({item}) => {
  const { collectionContractAddress, creator, duration, title, thumbnail_ipfs_url } =  item;
  return (
      <div className="videocard">
        <div className="thumbnail-container">
          <img className="w-full h-full object-cover" src={thumbnail_ipfs_url}/>
        </div>
        <div className="data-container">
          <p className="title">
            {title.length > 27 ? String(title).substring(0, 26) + "..." : title}
          </p>
          <p className="creator">
            {creator.length > 26 ? String(creator).substring(0, 25) + "..." : creator}
          </p>
        </div>
      </div>
  );
}

export default VideoCard;
