import React from 'react';

import AssetSelectionContext from './AssetSelectionContext';
import ReactAudioPlayer from 'react-audio-player';
import Utilities from '../../utility/utilities.js';
import { Player } from 'video-react';
import './ColumnB.css';

export default class ColumnB extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // console.log('context:', this.context); // 'this.context' is for class components
    }

    mashHandler(status) {
        // trigger the callBack
        this.props.parentCallback(status); // Note: This is temporarily enabled for mashlabs-demo reference
    }

    render() {
        return (
            <div className="ColumnB">
                <div className="video-title-wrapper">
                    <p className="video-title">{this.context.video.title}</p>
                </div>
                <div className="media-players">
                    <Player
                        playsInline
                        poster={this.context.video.thumbnail_ipfs_url}
                        src={this.context.video.media_ipfs_url}
                        className="video-player"
                    />

                    <div className="py-5">
                        <p className="audio-title">{this.context.audio.title}</p>
                    </div> 
                    
                    <ReactAudioPlayer
                        controls
                        src={this.context.audio.media_ipfs_url}
                        className="react-audio-player"
                    />
                </div>

                <div className="mash-button-wrapper">
                    <button style={{ backgroundColor: 'gray', padding: '10px 14px 10px 14px', marginTop: '20px'}} onClick={ () => this.mashHandler(true) } type="button">
                    CONFIRM SELECTIONS
                    </button>
                    <div style={{ marginTop: '10px' }}>
                    Minting on the Mash NFT platform is frozen at this time; please <a href="https://mashlabs.xyz" style={{textDecoration: "underline"}}>join the whitelist</a> for our private beta.
                    </div>
                </div>

            </div>
        )

    }
}

ColumnB.contextType = AssetSelectionContext; // You need this for class components