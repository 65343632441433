import React, { useState, useEffect } from 'react';
import Utilities from '../../utility/utilities';
import './CollectionDetails.css';
import { getCollectionFactoryContractInstance } from '../../interface/api/MashCollectionFactory.js'

const CollectionDetails = (props) => {

  const [ collections, setCollections ] = useState();

  useEffect(() => {
  async function fetchCollections() {
        const mash_contract_factory = await getCollectionFactoryContractInstance("0xc4af4A45515E3086eaEEfB8454Cd81905C4723e7");
        const solidity_collections = await mash_contract_factory.methods.getCollections().call();

        const collections = [];
        for (let i = 0; i < solidity_collections.length; ++i) {
            const collection = {
                "id": String(solidity_collections[i][0]),
                "collectionContractAddress": solidity_collections[i][10],
                "type": 'collection',
                "title": solidity_collections[i][3],
                "description": solidity_collections[i][4],
                "creator": solidity_collections[i][6],
                "visual_creator_name": solidity_collections[i][6],
                "audio_creator_name": solidity_collections[i][7],
                "price": solidity_collections[i][2],
                "supply": solidity_collections[i][1],
                "tags": [ 'scifi','tech'],
                "thumbnail_ipfs_url": solidity_collections[i][5]
            }
            collections.push(collection);
        }
        setCollections(collections);
    }
    fetchCollections();
  }, []);

  const gotoMashboard = () => {
    Utilities.goToLink(`/#/Mashboard`);
  }

  return (
    <div className='collection-details-container'>
      <p className="fields">
        <span className="collection-name">{ props.collection.title }</span>
        <span>●</span>
        <span className="royalties">10% royalties</span>
        <span>●</span>
        <span className="supply">{ props.collection.supply } Mints Available / { props.collection.supply } Mints Supplied</span>
      </p>
      <p className="fields">
        <span className="contract_address">Collection contract address: { props.collection.collectionContractAddress } (ETH Sepolia)</span>
      </p>
      <br></br>
     
      <div className="flex-container">
          {
            collections && collections.map((item) => {
                return (
                    <div className="collection-card-wrapper" onClick={() => gotoMashboard()} id={`collection-item${item.id}`} key={`collection-item${item.id}`}>
                        
                    </div>
                )
            })
          } 
      </div>
    </div>
  )
}

export default CollectionDetails;