import React from 'react';
import PropTypes from 'prop-types';
import './CollectionCard.css';

const CollectionCard = (props) => {
  const { collection_contract_address, creator, audio_creator_name, description, price, supply, title, thumbnail_ipfs_url } =  props.item;
  return (
    <>
      <div className="collection-card">
        <div className="thumbnail-container" style={{ backgroundImage: 'url(' + thumbnail_ipfs_url + ')' }}>
        </div>
        <div className="collection-data-container">
          <span className="title">
            {title.length > 19 ? String(title).substring(0, 18) + "..." : title}
          </span>
          <p className="creator">
            {creator.length > 24 ? String(creator).substring(0, 23) + "..." : creator} x {audio_creator_name.length > 24 ? String(audio_creator_name).substring(0, 23) + "..." : audio_creator_name}
          </p>
          <div className="description-container">
            <p className='description-text'>{description.length > 61 ? String(description).substring(0, 60) + "..." : description}</p>
          </div>
          <p className="unit-price"> {price} ETH unit price </p>
          <p className="supply">{supply} items</p>
        </div>
      </div>
    </>
  );
}

CollectionCard.propTypes = {
  item: PropTypes.shape({
    audio_creator_name: PropTypes.string.isRequired,
    creator: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    thumbnail_ipfs_url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired, 
    supply: PropTypes.number.isRequired,
  }),
}

export default CollectionCard;
