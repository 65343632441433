import React from 'react';
import { Link } from 'react-router-dom';
import { Player } from 'video-react';
import ReactAudioPlayer from 'react-audio-player';
import './Footer.css';

const Footer = (props) => {
  return (
    <div className="footer">

      <div>
          <hr style={{ margin: '8px 0px', width: '100%', borderTop: '1px solid #F5F5F5'}}></hr>
      </div>
      <div className="Nav">
        <Link to={`/`}>
          <span className="logo">mash</span>
        </Link>
      </div>
      <div className='social-media-wrapper'>
        <a href="https://twitter.com/MashLabsNFT" target="_new"><img className="icon-twitter" src="/images/icon_twitter.svg" /></a>
        {/* <a href="https://discord.gg/fuaqgMKp9j" target="_new"><img className="icon-discord" src="/images/icon_discord.svg" /></a>
        <a href="https://instagram.com/mashnft" target="_new"><img className="icon-instagram" src="/images/icon_instagram.svg" /></a> */}
      </div>

    </div>
  );
}

export default Footer;
