import React from 'react';
import { Link } from 'react-router-dom';
import './Splash.css';

function Splash() {
  return (
    <div className="splash">
      <div>
        <p className="welcome">
          Welcome to the new way to discover and engage with creative artists from around the world.
        </p>
        <p className="launcher-wrapper">
          <Link to='/collections'>
            <button style={{ backgroundColor: "#f18736", whiteSpace: "nowrap" }} className="inline-flex items-center justify-center px-2 py-0 border border-transparent text-base font-small text-white hover:bg-indigo-700">
              <span style={{ fontWeight: 600, fontSize: "18px", padding: "10px 20px" }}>
                explore collections
              </span>
            </button>
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Splash;
