const CONTRACT_ABI = require("../abi/mash_collection_factory_OLD.json");
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(
    "https://eth-sepolia.g.alchemy.com/v2/pUzxr-Vs9CImMU6Vf7mM3rFCoidSfEek"
);
export const getCollectionFactoryContractInstance = async (contract_address) => {
    return new web3.eth.Contract(CONTRACT_ABI, contract_address);
}

export const getCollections = async (mash_collection_factory_contract_instance) => {
  const mash_collections = await mash_collection_factory_contract_instance.methods.getCollections().call();
  return mash_collections;
};