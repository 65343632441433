import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './Router';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import makeServer from "./mock-server/server";
import './index.css';

Amplify.configure(config);

const container = document.getElementById('root');

// Create a root
const root = ReactDOM.createRoot(container);

// makeServer(); // activate mirage mock server

// Initial render
root.render(<Router />);