import React  from 'react';
import { useLocation } from 'react-router-dom';
import Utilities from '../utility/utilities';
import { Player } from 'video-react';
import './Nft.css';

const Nft = (props) => {

  const location = useLocation();
  const payLoad = location.payLoad;
  console.log("Nft.js: payLoad received: ", payLoad);

  return (
    <div className="nft-container">
      <h1>NFT Created</h1>
      <h2>SUCCESSFULLY MINTED AN NFT!</h2>
      <p>You have successfully created your very own NFT.</p>


      <div style={{ width: '40%' }}>
        <Player
            playsInline
            poster={payLoad.video.thumbnail}
            src={payLoad.video.path}
        />
      </div>

      <div style={{ textAlign: 'left' }}>
        <h4>Details</h4>
        <p><span>Title: </span><span>{payLoad.title}</span></p>
        <p><span>Description: </span><span>{payLoad.description}</span></p>
        <p><span>Length: </span><span>{payLoad.length}</span></p>
        <p><span>Tags: </span><span>{payLoad.tags}</span></p>
        <p><span>Video used: </span><span>{payLoad.video.title}</span></p>
        <p><span>Video artist: </span><span>{payLoad.video.creator}</span></p>
        <p><span>Audio used: </span><span>{payLoad.audio.title}</span></p>
        <p><span>Audio artist: </span><span>{payLoad.audio.creator}</span></p>
      </div>

      <p>
        <button onClick={ () => Utilities.goToLink('/share') }>SHARE on Social Media</button>
      </p>

      <p>
        <button onClick={ () => Utilities.goToLink('/profile') }>BACK to Profile</button>
      </p>

      <p>
        <button onClick={ () => Utilities.goToLink('/mashboard') }>BACK to Mashboard</button>
      </p>
      
    </div>
  );
}

export default Nft;
