import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import ArtistRow from './ArtistRow';
import Utilities from '../../utility/utilities';
import './ArtistDiscoveryPortal.css';

const ArtistDiscoveryPortal = (props) => {

  const artistList = [
    {
      collection_id: 1,
      artistName: 'Dirty Chefs',
      artistUsername: 'dirtychefs',
      creator_displayname:  'Dirty Chefs',
      creator_address: '0x392930383938292',
      title: 'Sunflowers',
      thumbnail_url: '',
      collectionName: 'Amuse-Bouche',
      collectionUrl: 'https://www.mashnft.xyz/#/mashboard?collection_id=2',
      audio: 'https://mashnft.mypinata.cloud/ipfs/QmNRnJ3xptqyXoXsXfvtNp4EqnHJVTiHnE36cX6KYBEvRU/dirtychefs_genc_1/Audio/1%20Dirty%20Chefs%20-%20Olde%20Thyme%20Tarte%20%28Intro%29.wav'
    },
    {
      collection_id: 2,
      artistName: 'Bastion',
      artistUsername: 'bastion',
      creator_displayname:  'Bastion',
      creator_address: '0x392930383938292',
      title: 'Relic',
      thumbnail_url: '',
      collectionName: 'Relic',
      collectionUrl: 'http://localhost:3000/#/mashboard?collection_id=3',
      audio: 'https://mashnft.mypinata.cloud/ipfs/QmNRnJ3xptqyXoXsXfvtNp4EqnHJVTiHnE36cX6KYBEvRU/dirtychefs_genc_1/Audio/1%20Dirty%20Chefs%20-%20Olde%20Thyme%20Tarte%20%28Intro%29.wav'
    },
    {
      collection_id: 3,
      artistName: 'Tycho',
      artistUsername: 'Tycho',
      creator_displayname:  'Tycho',
      creator_address: '0x392930383938292',
      title: 'The Dark Album',
      thumbnail_url: '',
      collectionName: 'The Dark Album',
      collectionUrl: 'https://www.mashnft.xyz/#/mashboard?collection_id=2',
      audio: 'https://mashnft.mypinata.cloud/ipfs/QmNRnJ3xptqyXoXsXfvtNp4EqnHJVTiHnE36cX6KYBEvRU/dirtychefs_genc_1/Audio/1%20Dirty%20Chefs%20-%20Olde%20Thyme%20Tarte%20%28Intro%29.wav'
    },
    {
      collection_id: 4,
      artistName: 'Max Cooper',
      artistUsername: 'maxcooper',
      creator_displayname:  'Max Cooper',
      creator_address: '0x392930383938292',
      title: 'The AudioVisual Continuum',
      thumbnail_url: '',
      collectionName: 'The AudioVisual Continuum',
      collectionUrl: 'https://www.mashnft.xyz/#/mashboard?collection_id=2',
      audio: 'https://mashnft.mypinata.cloud/ipfs/QmNRnJ3xptqyXoXsXfvtNp4EqnHJVTiHnE36cX6KYBEvRU/dirtychefs_genc_1/Audio/1%20Dirty%20Chefs%20-%20Olde%20Thyme%20Tarte%20%28Intro%29.wav'
    }
  ];

  console.log(artistList);

  const handleRequestCollabClick = (id, requestee) => {
    alert(`Your request for collaboration have been sent to ${requestee}.`);
  }

  const switchToVisualDiscovery = () => {
    Utilities.sendPayLoad(props, '/visualdiscoveryportal', {});
  }

  return (
    <div className="artistdiscoveryportal">
      <div className="discoveryportal__main">
        <div className="header-controller">
          <h1 className="section-title">Artist Discovery Portal</h1>
          <button onClick={() => { switchToVisualDiscovery() }}>Switch to Visual Discovery</button>
        </div>
        <h2>Audio Collections</h2>
        
        <div className="list">
          {
            artistList.map(item => {
              return (<ArtistRow item={item} key={item.collection_id} parentCallback={handleRequestCollabClick}></ArtistRow>)
            })
          }
        </div>
      </div>
    </div>
  )

}

export default ArtistDiscoveryPortal;