import React from 'react';
import ReactPlayer from 'react-player';
import './ArtistRow.css';

const ArtistRow = ({item, parentCallback}) => {
  
  const handleRequestCollabClick = (id, requestee) => {
    parentCallback(id, requestee);
  }

  return (
    <div className="artistrow">
      <div className="artistrow__main">
        <div className="artist-container">
          <div className="content-wrapper">
            <a href={`https://www.mashnft.xyz/#/${item.creator_displayname}`}>{item.creator_displayname}</a>
            <p className="label">Artist</p>
          </div>
        </div>
        <div className="collection-container">
          <div className="content-wrapper">
            <p><a href={item.collectionUrl}>{item.title}</a></p>
            <p className="label">Collection</p>
          </div>
        </div>
        <div className="media-container">
          <div className="content-wrapper">
            <img src="/images/waveform1.png" />
            <ReactPlayer 
              url={item.audio} 
              controls={true}
              width="100%"
              height="20px"
            />
          </div>
        </div>
        <div className="request-container">
          <button onClick={() => handleRequestCollabClick(item.collection_id, item.creator_displayname)} className="request-collab">Request Collaboration</button>
        </div>
      </div>
    </div>
  )

}

export default ArtistRow;