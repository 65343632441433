import React, { Component, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './UploadCollection.css';


export default function UploadCollection() {
  const location = useLocation();
  const incomingPayload = location.payLoad; // note: it's always called "payLoad" from the location object
  console.log('Incoming payLoad to UploadCollection page: ', incomingPayload );
  
  const [collectionThumbnailFile, setCollectionThumbnailFile] = useState(null); // for collection logo
  const [nftFile, setNftFile] = useState(null); // for collection logo

  const validationSchema = Yup.object().shape({
    collectionName: Yup.string()
      .required('Collection name is required')
      .matches(/^[a-zA-Z0-9]+$/, 'Alphanumeric characters only'),
    tokenSymbol: Yup.string()
      .required('Token symbol is required')
      .length(3, 'Token symbol must be 3 characters'),
    collectionThumbnail: Yup.string().url('Invalid image URL'),
    nftName: Yup.string()
      .required('NFT name is required')
      .matches(/^[a-zA-Z0-9]+$/, 'Alphanumeric characters only'),
     supply: Yup.string()
      .required('Supply is required')
      .matches(/^\d+$/, 'Supply must be an integer')
  });

  const handleSubmit = (values, { setSubmitting }) => {
    // Handle form submission logic here
    console.log('Submitted values:', values);
    setSubmitting(false);
  };

  const handleCollectionThumbnailChange = (event) => {
    const collectionThumbnailFile = event.target.files[0];
    if (collectionThumbnailFile && isValidFileType(collectionThumbnailFile)) {
      setCollectionThumbnailFile(collectionThumbnailFile);
    } else {
      setCollectionThumbnailFile(null);
      // optionally show an error message here
    }
  };

  const handleNftFileChange = (event) => {
    const nftFile = event.target.files[0];
    if (nftFile && isValidFileType(nftFile)) {
      setNftFile(nftFile);
    } else {
      setNftFile(null);
      // optionally show an error message here
    }
  };

  const isValidFileType = (file) => {
    // Add your logic to check for valid media file types (e.g., image, video)
    // You can use regular expressions or libraries like mime-types
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'mp4', 'mov'];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  }

  // ToDo:
  //  add Musician or Visual artist

  const handleUpload = async () => {
    console.log('attempting to upload.....');
    console.log('collectionThumbnailFile: ', collectionThumbnailFile);
    console.log('nftFile: ', nftFile);
    if (collectionThumbnailFile && nftFile) {
      console.log('looks like all are set to upload.....')
      try {
        const formData = new FormData();
        formData.append('file', collectionThumbnailFile);
        formData.append('file', nftFile);
  
        const response = await fetch('/api/upload', {
          method: 'POST',
          body: formData,
        });
  
        if (response.ok) {
          const data = await response.json();
          // Handle successful upload (e.g., display a success message or redirect)
          console.log('Files uploaded successfully:', data);
        } else {
          // Handle errors
          console.error('Upload failed:', response.statusText);
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error during upload:', error);
      }
    }
  };

  return (
    <div className="upload-collection">
      <h1 className='section-title'>Upload your collection</h1>
      <Formik initialValues={{ 
          collectionName: '', 
          tokenSymbol: '', 
          collectionThumbnail: '',
          nftFile: ''
        }} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <h2>Collection Details</h2>
            <div className='section'>
              <label htmlFor="collectionName">Collection Name:</label>
              <Field type="text" name="collectionName" id="collectionName" />
              <ErrorMessage name="collectionName" component="div" className="error" />
            </div>
            <div>
              <label htmlFor="tokenSymbol">Token Symbol:</label>
              <Field type="text" name="tokenSymbol" id="tokenSymbol" maxLength={3} />
              <ErrorMessage name="tokenSymbol" component="div" className="error" />
            </div>

            <div className='section'>
              <h3>Collection Thumbnail</h3>
              <div>
                <input type="file" id="collectionThumbnailFile" accept="image/*,video/*" onChange={handleCollectionThumbnailChange} />
                {collectionThumbnailFile && (
                  <div>
                    <p>Selected Collection Thumbnail file: {collectionThumbnailFile.name}</p>
                    {/* Optionally display a preview of the file here */}
                  </div>
                )}
              </div>
            </div>
       
            <h2>NFT Details</h2>
            <div className='section'>
              <label htmlFor="nftName">NFT Name:</label>
              <Field type="text" name="nftName" id="nftName" />
              <ErrorMessage name="nftName" component="div" className="error" />
            </div>
            <div>
              <label htmlFor="supply">Supply:</label>
              <Field type="text" name="supply" id="supply" />
              <ErrorMessage name="supply" component="div" className="error" />
            </div>
            <div>
                <h3>NFT File:</h3>
                <input type="file" id="nftFile" accept="image/*,video/*" onChange={handleNftFileChange} />
                {nftFile && (
                  <div>
                    <p>Selected NFT file: {nftFile.name}</p>
                    {/* Optionally display a preview of the file here */}
                  </div>
                )}
              </div>

            <button className="upload-collection-button" onClick={handleUpload} type="submit" disabled={isSubmitting}>
              Upload Collection
            </button>
            
          </Form>
        )}
      </Formik>
    </div>
  );
}

