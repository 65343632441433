import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import VisualRow from './VisualRow';
import Utilities from '../../utility/utilities';
import './VisualDiscoveryPortal.css';

const VisualDiscoveryPortal = (props) => {

  const artistList = [
    {
      collection_id: 1,
      artistUsername: 'Takashi',
      creator_displayname: 'Takashi Murakami',
      creator_address: '0x392930383938292',
      title: 'Flowers',
      thumbnail_url: '',
      collectionName: 'Flowers',
      collectionUrl: 'https://www.mashnft.xyz/#/mashboard?collection_id=2',
      collection: [
        { 
          thumbnail: 'https://mashnft.mypinata.cloud/ipfs/QmPcVAkUcFrymNytYv9XcBXxn3GZqCmHG5MVCd6H5ATEaN/B0NEHER01.png',
          thumbnail_ipfs_url: 'https://mashnft.mypinata.cloud/ipfs/QmPcVAkUcFrymNytYv9XcBXxn3GZqCmHG5MVCd6H5ATEaN/B0NEHER01.png',
          media_ipfs_url: '',
          url: ''
        },
        { 
          thumbnail: 'https://mashnft.mypinata.cloud/ipfs/QmPcVAkUcFrymNytYv9XcBXxn3GZqCmHG5MVCd6H5ATEaN/B0NEHER02.png',
          thumbnail_ipfs_url: 'https://mashnft.mypinata.cloud/ipfs/QmPcVAkUcFrymNytYv9XcBXxn3GZqCmHG5MVCd6H5ATEaN/B0NEHER02.png',
          media_ipfs_url: '',
          url: ''
        },
        { 
          thumbnail: 'https://mashnft.mypinata.cloud/ipfs/QmPcVAkUcFrymNytYv9XcBXxn3GZqCmHG5MVCd6H5ATEaN/B0NEHER03.png', 
          thumbnail_ipfs_url: 'https://mashnft.mypinata.cloud/ipfs/QmPcVAkUcFrymNytYv9XcBXxn3GZqCmHG5MVCd6H5ATEaN/B0NEHER03.png',
          media_ipfs_url: '',
          url: ''
        }
      ]
    },
    {
      collection_id: 12,
      artistUsername: 'Basquiat',
      creator_displayname: 'Jean-Michel Basquiat',
      creator_address: '0x392930383938292',
      title: 'Untitled (1982)',
      thumbnail_url: '',
      collectionName: 'Untitled (1982)',
      collectionUrl: 'https://www.mashnft.xyz/#/mashboard?collection_id=2',
      collection: [
        { 
          thumbnail: 'https://mashnft.mypinata.cloud/ipfs/QmTGERx5rGhYJinNQHEW9AbGseQDbeUmNLuP9SRdNdaBex/Touch.png', 
          thumbnail_ipfs_url: 'https://mashnft.mypinata.cloud/ipfs/QmTGERx5rGhYJinNQHEW9AbGseQDbeUmNLuP9SRdNdaBex/Touch.png',
          media_ipfs_url: '',
          url: ''
        },
      ]
    },
    {
      collection_id: 123,
      artistUsername: 'VincentV',
      creator_displayname:  'Vincent Van Gogh',
      creator_address: '0x392930383938292',
      title: 'Sunflowers',
      thumbnail_url: '',
      collectionName: 'Sunflowers',
      collectionUrl: 'https://www.mashnft.xyz/#/mashboard?collection_id=2',
      collection: [
        { 
          thumbnail: '/images/avatar1.png', 
          thumbnail_ipfs_url: '/images/avatar1.png',
          media_ipfs_url: '',
          url: ''
        },
        {
          thumbnail: '',
          thumbnail_ipfs_url: '',
          media_ipfs_url: '',
          url: ''
        },
      ]
    }
  ];

  console.log(artistList);

  const handleRequestCollabClick = (id, requestee) => {
    alert(`Your request for collaboration have been sent to ${requestee}.`);
  }

  const switchToArtistDiscovery = () => {
    Utilities.sendPayLoad(props, '/artistdiscoveryportal', {});
  }

  return (
    <div className="visualdiscoveryportal">
      <div className="discoveryportal__main">
        <div className="header-controller">
          <h1 className="section-title">Visual Discovery Portal</h1>
          <button onClick={() => { switchToArtistDiscovery() }}>Switch to Artist Discovery</button>
        </div>
        <h2>Visual Collections</h2>
        
        <div className="list">
          {
            artistList.map(item => {
              return (<VisualRow item={item} key={item.collection_id} parentCallback={handleRequestCollabClick}></VisualRow>)
            })
          }
        </div>
      </div>
    </div>
  )

}

export default VisualDiscoveryPortal;